/* prettier-ignore */
.app-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.04);
}

.nav-margin {
  margin-top: 62px;
}

.btn_registrar {
  color: #fff;
}

.btn_registrar:hover {
  color: #fff;
  text-decoration: none;
}

.btn_ingresar:hover {
  color: #007bff;
  text-decoration: none;
}

.center_signin_form {
  margin: 90 auto 0 auto;
}

.bg-navbar-web {
  background-color: var(--primary) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3), 0 2px 5px rgba(0, 0, 0, 0.2);
}

.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.15) !important;
  font-weight: 400;
  border-radius: 10px;
  transition: 0.2s ease;
}

.btn-primary:active {
  transform: scale(0.95);
}

.btn-primary:hover {
  background-color: var(--primaryImportant) !important;
  border-color: var(--primaryImportant) !important;
}

.btn-cta {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  transition: 0.3s ease !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  color: #fff;
  width: 280px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  font-weight: 300;
}

.btn-cta:hover {
  background-color: var(--primaryImportant) !important;
  border-color: var(--primaryImportant) !important;
  color: #fff;
}

@media (max-width: 768px) {
  .btn-cta {
    width: 100%;
    border-radius: 10px;
  }
}

.bg-btn-web {
  background-color: var(--primary) !important;
  color: #fff !important;
  height: 54px;
}

.bg-btn-web:hover {
  background-color: var(--primaryHover) !important;
}

.nav-btn {
  padding: 0px 0.5rem !important;
}

.btn-profile {
  margin: 5px;
  height: 44px;
  border-radius: 10px !important;
}

.btn-rounded {
  border-radius: 30px !important;
}

.btn-danger {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  color: #fff !important;
}

.btn-danger:hover {
  background-color: var(--secondaryHover) !important;
  border-color: var(--secondaryHover) !important;
  color: #fff !important;
  box-shadow: 0 0.225rem 0.45rem rgba(0, 0, 0, 0.135) !important;
}

.btn-outline-light:hover {
  color: var(--primary) !important;
}

.btn-secondary {
  background-color: var(--primaryHover) !important;
  border-color: var(--primaryHover) !important;
  color: #fff !important;
  border-radius: 10px;
}

.btn-secondary:hover {
  background-color: var(--primaryHover) !important;
  border-color: var(--primaryHover) !important;
  color: #fff !important;
  box-shadow: 0 0.425rem 0.45rem rgba(0, 0, 0, 0.135) !important;
  border-radius: 10px;
}

.cta-vertical-align {
  padding: 80px 0px !important;
}

.divider {
  height: 3px;
  width: 150px;
  background: var(--secondary);
  border-radius: 3px;
}

.footer-divider {
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 2px;
}

.footer-section-divider {
  height: 1px;
  width: 80%;
  background: #fff;
  border-radius: 1px;
}

.footer {
  background-color: #fff;
  border-color: var(--primary);
  height: 60px;
  color: #fff !important;
  border-radius: 20px !important;
  box-shadow: 0 -2px 8px rgba(198, 198, 198, 0.05),
    0 -1px 3px rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.07) 0px 2px 3px,
    rgba(0, 0, 0, 0.17) 0px 2px 4px;
}

.footer-link {
  color: rgb(201, 201, 201) !important;
}

.card-shadow {
  box-shadow: 0 0.425rem 0.45rem rgba(51, 103, 255, 0.135) !important;
}

.card-primary-button {
  background-color: var(--primary) !important;
  border-radius: 10px !important;
  color: #fff !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  opacity: 1 !important;
  transition: 0.2s ease;
}

.card-primary-button:hover {
  background-color: var(--primaryImportant) !important;
  color: #fff !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.link-primary-text {
  color: var(--primary) !important;
}

.link-primary-text:hover {
  color: var(--primary) !important;
  text-decoration: underline;
}

.custom-vertical-align {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;

  -ms-justify-content: center;
  -webkit-justify-content: center;

  justify-content: center;
}

.bg-web-secondary {
  background-color: var(--primary) !important;
  color: #fff !important;
}

.card-header-rounded {
  border-radius: calc(0.9375rem - 1px) calc(0.9375rem - 1px) 0 0 !important;
}

.card-footer-rounded {
  border-radius: 0 0 calc(0.9375rem - 1px) calc(0.9375rem - 1px) !important;
}

.btn-card {
  padding: 1.25rem !important;
}

#preped-card {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  margin-left: -1px;
}

.bg-dark {
  background-color: var(--primary) !important;
}

.crop-text-3 {
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.breadcrumb-separation {
  top: 62px !important;
  z-index: 2000;
  background-color: rgba(245, 245, 245, 0.9);
}

.content-my-providers {
  margin-top: 126px;
  padding-bottom: 5.4rem;
}

.content-manage-providers {
  margin-top: 125px;
  padding-bottom: 4.5rem;
}

.content-shopping-cart {
  margin-top: 125px;
  padding-bottom: 5.5rem;
}

.search-bar-margin {
  top: 62px !important;
  z-index: 1000;
}

@media (min-width: 768px) {
  .welcome-backgrond-image {
    background-image: url(/img/fondo-hero-section.png),
      radial-gradient(ellipse, #ff9d0a 0%, #ffaf10 20%, #ffe735 85%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    border-radius: 0px !important;
  }

  .breadcrumb-separation {
    z-index: 1000;
  }

  .search-bar-margin {
    top: 70px !important;
    z-index: 1000;
  }

  .content-my-providers {
    margin-top: 135px;
    padding-bottom: 3rem;
  }

  .content-manage-providers {
    margin-top: 125px;
    padding-bottom: 5rem;
  }

  .content-shopping-cart {
    margin-top: 125px;
    padding-bottom: 4rem;
  }

  .full-height {
    height: 91vh !important;
  }

  .full-height-contact-us {
    height: 90vh !important;
  }

  .border-action-card {
    border-radius: 70px !important;
  }

  .btn-action-card {
    border-radius: 30px !important;
  }

  .blockquote-card {
    border-radius: 80px !important;
  }

  .contact-card-width {
    width: 750px !important;
  }

  .contact-card-btn {
    padding-left: 20px !important;
    padding-right: 0px !important;
  }

  .view-courses-card {
    width: 70% !important;
  }

  .secondary-jumbotron-height {
    height: 33vh;
    border-radius: 0 !important;
  }

  .statistics-text-secondary {
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    margin: 2rem 16rem;
  }

  .about-services-list {
    max-width: 688px;
  }

  .about-card-text {
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: #3367ff !important;
    line-height: normal;
  }
}

@media (max-width: 768px) {
  .welcome-backgrond-image {
    background-image: radial-gradient(
      ellipse,
      #ff9d0a 0%,
      #ffaf10 20%,
      #ffe735 85%
    );
    background: url(/img/fondo-hero-section.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    margin: 0;
  }

  .full-height {
    height: 92vh !important;
    min-height: 610px !important;
  }

  .full-height-contact-us {
    height: auto !important;
  }

  .border-action-card {
    border-radius: 15px !important;
  }

  .blockquote-card {
    border-radius: 15px !important;
  }

  .btn-action-card {
    display: block;
    width: 100%;
    border-radius: 0.25rem !important;
  }

  .body-quote {
    height: 100px !important;
  }

  .secondary-jumbotron-height {
    height: 70vh !important;
    border-radius: 0 !important;
  }

  .header-image-container {
    height: 30vh !important;
  }

  .statistics-text-secondary {
    font-size: 1.5rem !important;
    font-weight: 500 !important;
  }

  .text-info-services {
    text-align: center;
  }

  .about-services-list {
    max-width: 320px;
  }

  .about-card-text {
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    color: #3367ff !important;
    text-align: center;
    line-height: normal;
  }
}

.left-form-background {
  /* background-image: linear-gradient(-60deg, #51487d, #201468); */
  background-image: url(/img/fondo-contacto.png);
  background-size: cover;
  background-repeat: 'no-repeat';
  background-position: center;
  color: #fff;
}

.contact-left-layer {
  background-color: rgba(50, 50, 50, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rigth-form-background {
  background: url(/img/Fondo-Subscription-Form.png);
  background-size: cover;
  background-repeat: 'no-repeat';
  background-position: center;
}

.contact-rigth-layer {
  background-color: rgba(50, 50, 50, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-form-text {
  color: #1d1d1d !important;
}

label {
  color: var(--secondary) !important;
}

.botom-card-text {
  font-size: 14px;
  color: var(--secondary) !important;
}

.meetus-backgrond-image {
  background: url(/img/Fondo-Conocenos-Home.png) no-repeat;
  background-size: cover;
  border-radius: 0px !important;
}

.blockquote-font-size {
  font-size: 1rem !important;
}

.carousel-review-item {
  height: 200 !important;
}

.course-card-title {
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #3367ff !important;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick' !important;
  font-size: 30px !important;
  line-height: 1 !important;
  opacity: 0.65 !important;
  color: #1d1d1d !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.slick-prev:hover,
.slick-next:hover {
  font-family: 'slick' !important;
  font-size: 30px !important;
  line-height: 1 !important;
  opacity: 0.95 !important;
  color: #1d1d1d !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.slick-prev:active,
.slick-next:active {
  opacity: 0.6 !important;
}

.all-cards:hover {
  box-shadow: 0 0 0.425rem 0.45rem rgba(20, 23, 28, 0.1),
    0 1px 1px 0 rgba(20, 23, 28, 0.1) !important;
  opacity: 1 !important;
  transition: 0.2s ease;
}

.rating-position {
  position: absolute;
  margin-top: 5px;
}

.course-background {
  background-image: linear-gradient(
    60deg,
    rgba(123, 118, 255, 0.6),
    rgba(214, 118, 255, 0.6)
  );
  color: #fff;
}

.image-center {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.stadistics-card {
  border: none !important;
}

.stadistics-card-title {
  font-size: 2.5rem !important;
  font-weight: 600 !important;
  color: #3367ff !important;
  text-align: center;
}

.statistics-card-text {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  color: #3367ff !important;
  text-align: center;
  line-height: normal;
}

.steps-card {
  border: none !important;
}

.steps-card-title {
  font-size: 2.5rem !important;
  font-weight: 600 !important;
  color: var(--primary) !important;
  text-align: center;
}

.steps-card-text {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  color: var(--secondary) !important;
  text-align: center;
  line-height: normal;
}

.image-background-color {
  background: rgba(0, 0, 0, 0.125);
}

/* Course Card Skeleton */
.image-skeleton {
  position: relative;
  height: 151px;
  width: 100%;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 15px 15px 0 0;
}

.text-full-width-skeleton {
  position: relative;
  height: 16px;
  width: 100%;
  background: rgb(240, 240, 240);
  border-radius: 5px;
}

.text-almost-full-width-skeleton {
  position: relative;
  height: 16px;
  width: 80%;
  background: rgb(240, 240, 240);
  border-radius: 5px;
}

.text-half-width-skeleton {
  position: relative;
  height: 16px;
  width: 50%;
  background: rgb(240, 240, 240);
  border-radius: 5px;
}

.button-skeleton {
  position: relative;
  height: 32px;
  width: 100%;
  background: rgb(240, 240, 240);
  border-radius: 5px;
}

.animated::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 80%;
  top: -0;
  left: -1000px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
  animation: progress 1.5s ease-in-out infinite;
}

.left-about-services-background {
  background: url(/img/Fondo-About-Left.png) no-repeat;
  background-size: cover;
  color: #fff;
}

.services-info-background {
  background: rgba(33, 20, 104, 85%);
  font-weight: 700;
}

.btn-card-cta {
  border-radius: 50px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}

@media (max-width: 768px) {
  .btn-card-cta {
    border-radius: 10px;
    width: 100%;
  }
  .dropdown {
    margin: 10px;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--primary);
  border-radius: 5px;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
  padding: 10px;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.account-list-options {
  background-color: #fff !important;
  color: #000 !important;
  border: none !important;
  border-bottom: 1px solid rgb(230, 230, 230) !important;
  /* border-top: 1px solid rgb(230, 230, 230) !important; */
}

.account-list-options:first-of-type {
  border-top: 1px solid rgb(230, 230, 230) !important;
}

.account-signout-option {
  background-color: #fff !important;
  color: red !important;
  border: none !important;
  border-bottom: 1px solid rgb(230, 230, 230) !important;
  border-top: 1px solid rgb(230, 230, 230) !important;
}

.toggle-button {
  -webkit-appearance: none;
  width: 45px;
  height: 25px;
  border-radius: 30px;
  border: 2px solid var(--primary);
  cursor: pointer;
  right: 0px;
  position: absolute;
}

.toggle-button::before,
.toggle-button::after {
  content: '';
  position: absolute;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}

.toggle-button::before {
  height: 20px;
  width: 20px;
  background-color: var(--primary);
  border-radius: 50%;
  left: 1px;
}

.toggle-button::after {
  height: 8px;
  width: 8px;
  background-color: #7a7a7a;
  border-radius: 50%;
  left: -20px;
}

.toggle-button:checked::before {
  left: 20px;
}

.toggle-button:checked::after {
  background-color: #55f696;
}

.image-select-btn {
  width: 100%;
  background-color: var(--secondary) !important;
  color: #fff;
  border-radius: 10px;
  outline: none !important;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13), 0 3px 5px rgba(0, 0, 0, 0.23) !important;
}

.image-select-btn:hover {
  background-color: #007bff !important;
}

.image-select-btn:active,
.image-select-btn:focus {
  background-color: #007bff !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.13), 0 2px 4px rgba(0, 0, 0, 0.23) !important;
  outline: none !important;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 26px;
  outline: 0;
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px !important;
}

.react-tagsinput--focused {
  border: 1px solid var(--primary) !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(210, 101, 26, 0.45) !important;
}

.react-tagsinput-tag {
  background-color: #dcebfc !important;
  border-radius: 8px !important;
  color: var(--secondary) !important;
  border: none !important;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 15px !important;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 100px !important;
}

.horizontal-scroll {
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
  /* scroll-snap-type: both mandatory; */
}

body::-webkit-scrollbar {
  display: none;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

.product-card {
  height: 150px;
  width: 200px;
  min-width: 200px;
  border-radius: 10px;
  border: none;
  transition: 0.4s fade;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
}

.category-tag {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: var(--primary);
  color: #fff;
  margin-right: 0.5rem;
}

.product-tag {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 10px;
  background-color: #dcebfc;
  color: var(--secondary);
  margin-right: 0.5rem;
}

.product-tag:last-of-type {
  margin-right: 0;
}

.carousel-root {
  outline: none;
  height: 350px;
}

@media (max-width: 768px) {
  .carousel-root {
    outline: none;
    height: 250px;
  }
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
  min-height: 280px;
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: transparent !important;
}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  text-align: center;
  width: 100%;
  padding: 0 !important;
}

.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: var(--primary) !important;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.btn-location {
  background-color: transparent !important;
  color: var(--primary);
  border: 1px solid var(--primary);
  box-shadow: none !important;
  border-radius: 0 5px 5px 0 !important;
  transition: 0.2s ease;
}

.btn-location:hover,
.btn-location:active,
.btn-location:focus {
  background-color: var(--primaryImportant) !important;
  color: #fff !important;
}

.btn-edit {
  background-color: #dcebfc;
  padding: 10px;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  transition: 0.2s ease;
}

.btn-edit:active {
  background-color: #b0ccec !important;
  transform: scale(0.95);
}

.btn-edit-image {
  position: absolute;
  bottom: 0.85rem;
  right: 0.85rem;
  background-color: #dcebfc;
  padding: 10px;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  transition: 0.2s ease;
}

.btn-edit-image:active {
  background-color: #b0ccec !important;
  transform: scale(0.95);
}

.btn-close {
  background-color: #fcc8c8;
  color: red;
  padding: 10px;
  height: 20px;
  width: 20px;
  border-radius: 30px;
}

.btn-cancel {
  border-radius: 10px;
  font-weight: 400;
  background-color: #fcc8c8 !important;
  border: 1px solid #fcc8c8 !important;
  color: red;
  box-shadow: none !important;
}

.btn-cancel:hover,
.btn-cancel:focus,
.btn-cancel:active {
  color: red !important;
  background-color: #ffacac !important;
  border: 1px solid #ffacac !important;
}

.input-form-group {
  margin-bottom: 0.5rem !important;
}

.input-form-group:last-of-type {
  margin-bottom: 0 !important;
}

.firebaseui-idp-text.firebaseui-idp-text-long {
  display: none !important;
}

.firebaseui-idp-text.firebaseui-idp-text-short {
  display: table-cell !important;
  font-size: 1.1rem;
  text-align: center !important;
}

.firebaseui-idp-button {
  font-size: 1.1rem !important;
  height: 50px !important;
  width: 150px !important;
  border-radius: 50px !important;
}
.firebaseui-card-content {
  display: flex;
  justify-content: center;
  padding: none;
}

.firebaseui-idp-list {
  display: flex !important;
  margin: 0 !important;
}

.firebaseui-list-item {
  margin: 5px !important;
}

.whatsapp-share-btn {
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mail-share-btn {
  background-color: var(--primary);
  color: #fff;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-share-btn {
  background: radial-gradient(
    circle at top right,
    rgb(136, 197, 255) 0%,
    rgba(0, 132, 255, 1) 70%,
    rgba(0, 132, 255, 1) 100%
  );
  color: #fff;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded {
  border-radius: 0.5rem !important;
}

.delete-item {
  background-color: #fcc8c8 !important;
  color: red !important;
  border: none !important;
  border-radius: 10px;
  padding: 10px;
  transition: 0.2s ease;
}

.delete-item:active {
  transform: scale(0.95);
}

.delete-item:active,
.delete-item:hover {
  background-color: #fba0a0 !important;
}

.main-office-tag {
  position: absolute;
  height: 28px;
  top: 10px;
  right: -5px;
  padding: 5px 10px;
  background-color: var(--primary);
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.2), -1px 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 15px 0px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.main-office-tag-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 5px solid var(--primary);
  border-right: 5px solid transparent;
  top: 38px;
  right: -5px;
  z-index: 2;
}

.breadcrumb {
  flex-wrap: nowrap !important;
}

.full-width {
  width: 100%;
}

.infinite-scroll-component__outerdiv {
  height: 85%;
}

.product-card-home {
  width: 80vw;
  min-width: 260px;
  max-width: 290px;
  height: 370px;
  border: none;
  transition: 0.4s fade;
  cursor: pointer;
  vertical-align: top;
  border-radius: 25px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 8px 18px rgba(0, 0, 0, 0.1);
}

.product-card-home-container:last-of-type {
  padding-right: calc(1rem + 10px);
}

.provider-card-home {
  width: 250px;
  border-radius: 10px;
  border: none;
  transition: 0.4s fade;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin-right: 1rem;
}

.provider-card-home:last-of-type {
  margin-right: 0;
}

.btn-load-more-inner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-load-more {
  background-color: #dcebfc;
  height: 140px;
  width: 140px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: var(--secondary);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: 0.2s ease;
}

.btn-load-more:active {
  background-color: #b0ccec !important;
  transform: scale(0.95);
}

.noselect,
body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

.list-group-item.active {
  z-index: 2;
  color: #495057 !important;
  background-color: transparent !important;
  border-color: var(--primary);
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.expectative-background {
  background-image: url(/img/fondo-modal-espectativa.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
}

.btn-close-modal {
  height: 25px;
  width: 25px;
  background-color: #ffffff80;
  border-radius: 50%;
  top: 15px;
  right: 15px;
  position: absolute;
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-close-modal:active {
  background-color: #ffffffba !important;
  transform: scale(0.95);
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: var(--secondary);
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 15px;
  padding-top: 10px;
  position: relative;
  width: 40px;
  height: 40px;
}

.countdown-item span {
  color: var(--secondary);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.secondary-text-color {
  color: var(--secondary) !important;
}

.btn-restart-filter {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  padding: 5px 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.15) !important;
  font-weight: 400;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-restart-filter:active {
  transform: scale(0.95);
}

.btn-whatsapp-contact {
  text-decoration: none !important;
  background-color: #25d366;
  height: 45px;
  color: #fff;
  font-size: 18px;
  font-weight: 500px;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-whatsapp-contact:active {
  text-decoration: none !important;
  color: #fff !important;
  transform: scale(0.95);
}

.btn-whatsapp-contact:hover {
  text-decoration: none !important;
  color: #fff !important;
}

.btn-contact {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.17);
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-contact:active {
  color: #fff !important;
  transform: scale(0.95);
}
.cart-quantity-header {
  text-align: center;
  flex: 1.1;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.cart-item {
  flex: 2;
}

.cart-quantity-body {
  text-align: center;
  flex: 1.1;
  border-left: 1px solid var(--secondary);
  border-right: 1px solid var(--secondary);
}

.cart-value {
  text-align: center;
  flex: 1;
}
.bottom-nav {
  position: relative;
  width: 50px;
  height: 60px;
  /* color: #6f8faf; */
  color: var(--secondary);
  font-size: 10px;
  cursor: pointer;
  transition: 0.3s ease;
}

.bottom-nav-active {
  position: relative;
  width: 50px;
  height: 60px;
  color: var(--primary);
  font-weight: 500;
  font-size: 0.7rem;
  cursor: pointer;
  transition: 0.3s ease;
}

.btn-add-to-cart {
  height: 45px;
  background-color: var(--secondary);
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-add-to-cart:active {
  background-color: #0374fd;
  transform: scale(0.95);
}

.btn-add-to-cart.btn-disabled {
  background-color: #89b1e0;
  pointer-events: none;
}

.btn-add-substract-product-count {
  background-color: #dcebfc;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-add-substract-product-count:active {
  background-color: #b0ccec !important;
  transform: scale(0.95);
}

.btn-confirm-purchase {
  background-color: var(--secondary);
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.17);
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-confirm-purchase:active {
  transform: scale(0.95);
}

.btn-arrow-back {
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  cursor: pointer;
}

.btn-arrow-back:active {
  background-color: #dcebfc;
  transition: 0.2s ease;
}

.page-title-with-arrow {
  padding-right: 35px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: var(--secondary);
}

.page-title-without-arrow {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: var(--secondary);
}

.app {
  padding: 20px 0;
  display: grid;
  grid-gap: 20px 0;
  grid-template-columns: 20px 1fr 20px;
  align-content: start;
}

.app > * {
  grid-column: 2 / -2;
}

.app > .full {
  grid-column: 1 / -1;
}

.hs {
  display: grid;
  grid-gap: calc(30px / 2);
  grid-template-columns: 10px;
  grid-template-rows: minmax(150px, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: calc(85% - 20px * 2);

  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: 25px;
  /* margin-bottom: calc(-0.25 * 20px); */
}

.hs:before,
.hs:after {
  content: '';
  width: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

.app {
  width: 360px;
  min-width: 360px;
  /* height: 470px; */
  /* background: #dbd0bc; */
  overflow-y: scroll;
}

.hs > li,
.item {
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: #fff; */
}

.no-scrollbar {
  scrollbar-width: none;
  margin-bottom: 0;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 414px) {
  .providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .my-providers-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .image-404 {
    max-width: 300px;
  }
}
/* iPhone XR */
@media only screen and (max-idth: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .my-providers-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* iPhone XS */
@media only screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .my-providers-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* iPhone XS Max */
@media only screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .my-providers-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 415px) and (max-width: 768px) {
  .providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .my-providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .image-404 {
    max-width: 300px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .my-providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) {
  .providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .my-providers-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .image-404 {
    max-width: 400px;
  }
}

.btn-providers-view-more {
  width: 60px;
  height: 60px;
  background-color: rgba(220, 235, 252, 0.8);
  color: var(--secondary);
  border-radius: 12px;
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-product-view-more {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s ease;
}

.btn-product-view-more:active {
  transform: scale(0.95);
}

.card-providers-view {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  padding: 10px;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.btn-display-accordion {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #e3e3e3;
  cursor: pointer;
  transition: 0.3s ease;
}

.product-image-preview {
  border-radius: 12px;
  object-fit: cover;
  transition: 0.3s ease;
}

.product-image-preview:active {
  transform: scale(0.95);
}

.notification-container {
  width: 100%;
  max-width: 380px;
  height: 150px;
  transition: height 0.5s ease;
}

.notification-card {
  position: absolute;
  width: 100%;
  min-height: 80px;
  border-radius: 20px;
  margin-bottom: 12px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  transition: transform 0.5s ease;
}

.btn-more-less-notificactions {
  width: 85px;
  height: 30px;
  text-align: center;
  border-radius: 20px;
  background-color: #e3e3e3;
  color: rgb(109, 109, 109);
  cursor: pointer;
  transition: 0.3s ease;
}

.btn-more-less-notificactions:active {
  background-color: #e3e3e3;
  transform: scale(0.95);
}

.btn-hide-notifications {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffdada;
  cursor: pointer;
  transition: 0.3s ease;
}

.btn-hide-notifications:active {
  background-color: #d6d6d6;
  transform: scale(0.95);
}

.btn-more-notifications {
  width: 100%;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.btn-load-more-notifications {
  max-width: 380px;
  border-radius: 10px;
  color: #fff;
  background-color: #4086dc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18), 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.3s ease;
}

.btn-load-more-notifications:active {
  background-color: var(--secondary);
  transform: scale(0.95);
}

.btn-contact-complete {
  height: 25px;
  background-color: var(--primary);
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.17);
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-contact-complete:active {
  background-color: #ff6900;
  transform: scale(0.95);
}

.modal-backdrop {
  z-index: 5000 !important;
}

.modal {
  z-index: 5001 !important;
}

.btn-back-modal {
  width: 100px;
  background-color: #d9d9d9 !important;
  color: #545454;
  border-color: #d9d9d9 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 3px 5px rgba(0, 0, 0, 0.15) !important;
  transition: 0.3s ease;
}

.btn-back-modal:active,
.btn-back-modal:hover {
  background-color: #b2b2b2 !important;
  color: #f9f9f9;
  border-color: #b2b2b2 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 3px 5px rgba(0, 0, 0, 0.15) !important;
}

.pagination-dot {
  height: 5px;
  width: 5px;
  border-radius: 5px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: grey;
  transition: 0.2s ease;
}

.pagination-dot-active {
  height: 7px;
  width: 7px;
  border-radius: 7px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--primary);
}

.product-purchase-view {
  border-bottom: 1px solid var(--secondary);
}

.search-btn {
  background-color: #fff !important;
  color: var(--primary) !important;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 1px solid var(--primary);
}

.search-btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 12px rgba(0, 0, 0, 0.12);
  color: #fff !important;
}

.providers-new-grid-view {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding-left: 20px;
  padding-right: 20px;
}

.providers-new-grid-view-provider {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.nav-indicator {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  background-color: var(--primary);
  border-radius: 0;
  transition: 0.3s ease;
}

.bottom-nav-active .nav-indicator {
  width: 45px;
  height: 3px;
  border-radius: 5px;
}

.search-container {
  position: relative;
  width: 100%;
}

.btn-clear-search {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #e3e3e3;
  border-radius: 50px;
  color: var(--gray);
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-clear-search:active {
  background-color: #c1bfbf;
  transform: translateY(-50%) scale(0.95);
}

.ais-SearchBox {
  width: 100%;
}

.ais-SearchBox-input {
  border: 1px solid var(--primary);
  height: 38px;
  max-width: 900px;
  width: 100%;
  border-radius: 10px;
  padding: 0px 10px;
}

.ais-SearchBox-submit {
  display: none;
}

.ais-SearchBox-reset {
  display: none;
}

.btn-add-to-cart {
  height: 25px;
  background-color: var(--secondary);
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.17);
  cursor: pointer;
  transition: 0.2s ease;
}

.btn-contact-complete:active {
  background-color: #ff6900;
  transform: scale(0.95);
}

.algolia-search-box {
  width: 0%;
  height: 0px;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
}

.show-search-box {
  width: 100% !important;
  height: 100% !important;
  visibility: visible;
  margin-top: 5px;
  opacity: 1;
  transition: 0.5s ease;
}

.margin-top-providers-search {
  margin-top: 130px;
  transition: 0.3s ease;
}

.new-margin-top-providers-search {
  margin-top: 175px;
  transition: 0.3s ease;
}

.btn-back-to-home {
  background-color: var(--primary);
  color: #fff;
  padding: 7px 20px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18), 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease;
}

.btn-back-to-home:active {
  background-color: var(--primaryImportant);
  transform: scale(0.95);
}

.action-button {
  position: fixed;
  bottom: 5rem;
  right: 15px;
  width: 60px;
  height: 60px;
  background-color: var(--primary);
  border-radius: 50%;
  z-index: 99;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), 0 3px 6px rgba(0, 0, 0, 0.4);
  transition: 0.2s ease;
}

.action-button:active {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 6px 10px rgba(0, 0, 0, 0.4);
  background-color: var(--primaryImportant);
  transform: scale(0.95);
}

.background-credit-card {
  background-image: url(/img/promos-credit-card.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: initial;
}

.Toastify__toast--info {
  background-color: var(--secondary) !important;
}

.Toastify__toast--error {
  background-color: red !important;
}

.Toastify__toast-container {
  margin-left: 10px !important;
  margin-right: 20px !important;
  max-width: 320px !important;
}

.Toastify__toast {
  border-radius: 10px !important;
}

.Toastify__toast-body {
  padding-left: 20px !important;
}

.start-modal {
  margin: 0;
  padding: 10px;
  width: 100%;
  max-width: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--secondary);
  border-radius: 20px;
}

.sales-grid-view {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.progress-bar {
  background-color: var(--secondary);
}

.whatsapp-sale-btn {
  background-color: #25d366 !important;
  border: none;
}

.whatsapp-sale-btn:hover,
.whatsapp-sale-btn:active {
  background-color: #27a254 !important;
  box-shadow: none !important;
}

.call-sale-btn {
  background-color: var(--secondary) !important;
  border: none;
}

.call-sale-btn:hover,
.call-sale-btn:active {
  background-color: #194882 !important;
  box-shadow: none !important;
}

.card-in-page {
  max-width: 440px;
  min-width: 100%;
  border-radius: 20px;
  border: none;
  box-shadow: 0 2px 3px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.17);
}

.card-in-page-body {
  font-size: 20px;
  font-weight: 500;
  color: var(--secondary);
  padding: 10px 1rem;
}

.btn-secondary-brand-color {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.15) !important;
  font-weight: 400;
  border-radius: 10px;
  transition: 0.2s ease;
}

.btn-secondary-brand-color:active {
  transform: scale(0.95);
}

.btn-secondary-brand-color:hover {
  background-color: var(--secondaryHover) !important;
  border-color: var(--secondaryHover) !important;
}